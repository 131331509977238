import React, { useMemo, memo, Fragment, useState } from 'react';
import { Formik, Field } from 'formik';
import { useToasts } from "react-toast-notifications";
import { uniqBy } from 'lodash';
import { useIntl } from 'react-intl';
import { Modal } from 'react-bootstrap';
import LoadingDialog from '../../../ProductsStore/product-new/LoadingDialog';
import { useMutation, useQuery } from '@apollo/client';
import Table from 'rc-table';
import 'rc-table/assets/index.css';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import query_scGetWarehouseMapping from '../../../../../graphql/query_scGetWarehouseMapping';
import query_sme_catalog_stores from '../../../../../graphql/query_sme_catalog_stores';
import { ReSelect } from '../../../../../_metronic/_partials/controls/forms/ReSelect';
import mutate_scUpdateWarehouseMapping from '../../../../../graphql/mutate_scUpdateWarehouseMapping';
import { Checkbox } from '../../../../../_metronic/_partials/controls';
import PaginationModal from '../../../../../components/PaginationModal';

const LINK_MULTI = 1;
const LINK_SINGLE = 0;
const MappingWarehouseDialog = ({ onHide, show, storeId }) => {
    const { formatMessage } = useIntl();
    const { addToast } = useToasts();
    const [showSelectWh, setShowSelectWH] = useState(null); //sc_warehose_id
    const [whSelected, setWhSelected] = useState({})
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(1)

    const OPTIONS_LINK_WAREHOUSE = [
        { value: LINK_SINGLE, label: formatMessage({ defaultMessage: "Liên kết 1-1" }) },
        { value: LINK_MULTI, label: formatMessage({ defaultMessage: "Liên kết 1-nhiều" }) }
    ]

    const { data: dataScWarehouseMapping, loading: loadingScWarehouseMapping } = useQuery(query_scGetWarehouseMapping, {
        variables: {
            store_id: storeId
        },
        fetchPolicy: "cache-and-network",
    });

    const { data: dataSmeWarehouse } = useQuery(query_sme_catalog_stores, {
        fetchPolicy: 'cache-and-network'
    })

    const [scUpdateWarehouseMapping, { loading: loadingScUpdateWarehouseMapping }] = useMutation(mutate_scUpdateWarehouseMapping, {
        awaitRefetchQueries: true,
        refetchQueries: ['scGetWarehouseMapping', 'sc_stores_warehouse']
    });

    const optionsSmeWarehouse = useMemo(() => {
        if (!dataSmeWarehouse?.sme_warehouses || dataSmeWarehouse?.sme_warehouses?.length == 0) return [];

        const options = dataSmeWarehouse?.sme_warehouses?.map(wh => ({
            ...wh,
            value: wh?.id,
            label: wh?.name,
        }))

        return options
    }, [dataSmeWarehouse]);

    const initialValues = useMemo(
        () => {
            if (!dataScWarehouseMapping || dataScWarehouseMapping?.scGetWarehouseMapping?.length == 0) return {
                isEdit: false,
                isMutil: false,
                enable_map_multiple_wh: !!show?.enable_map_multiple_wh ? LINK_MULTI : LINK_SINGLE,
            };

            const m = {};
            const link = []
            dataScWarehouseMapping?.scGetWarehouseMapping?.forEach(mp => {
                const { sc_warehouse_id, sme_warehouse_id } = mp;
                if (!link.includes(sc_warehouse_id)) link.push(sc_warehouse_id)
                if (!m[sc_warehouse_id]) m[sc_warehouse_id] = [];
                if (!!sme_warehouse_id) m[sc_warehouse_id].push(sme_warehouse_id)
            })

            setWhSelected(m)
            const scWarehouseValues = {}

            Object.keys(m).forEach(sc_warehouse_id => {
                const sme_warehouse_ids = m[sc_warehouse_id];
                const _whMapping = optionsSmeWarehouse?.filter(wh => !!sme_warehouse_ids?.includes(wh?.value))
                scWarehouseValues[`sme_warehouse_mapping_${sc_warehouse_id}`] = _whMapping?.length == 0 ? null : _whMapping
            })

            // console.log("scWarehouseValues", scWarehouseValues);
            return {
                ...scWarehouseValues,
                isEdit: false,
                isMutil: link.length > 1,
                enable_map_multiple_wh: !!show?.enable_map_multiple_wh ? LINK_MULTI : LINK_SINGLE
                // link.length != (dataScWarehouseMapping?.scGetWarehouseMapping ?? [])?.length ? LINK_MULTI : LINK_SINGLE // nếu set các sme_warehouse_id có length < list data thì tức là có ít nhất 1 kho gian hàng liên kết nhiều kho vật lý
            }
        }, [optionsSmeWarehouse, dataScWarehouseMapping]
    );

    const getRow = (data) => {
        let d = [];
        data?.forEach(_ => {
            if (!d.some(__ => __?.sc_warehouse_id == _?.sc_warehouse_id)) d.push(_)
        })
        return d
    }

    const onHideSelectWh = () => {
        setShowSelectWH(null); setSearchText('')
    }
    let totalRecord = dataSmeWarehouse?.sme_warehouses?.length || 0;
    let totalPage = Math.ceil(dataSmeWarehouse?.sme_warehouses?.length / 5);
    const dataWh = (dataSmeWarehouse?.sme_warehouses || []).filter(w => {
        if (!searchText) return true;
        return w?.name?.includes(searchText) || w?.code?.includes(searchText)
    })
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={null}
            enableReinitialize
        >
            {({
                handleSubmit,
                values,
                validateForm,
                setFieldValue,
                errors,
                touched,
                setFieldTouched,
                ...rest
            }) => {
                const isEdit = Object.keys(values)?.filter(_ => _?.includes('isEdit')).some(_ => !!values[_]);

                return (
                    <Fragment>
                        <LoadingDialog show={loadingScUpdateWarehouseMapping} />
                        <Modal
                            show={show}
                            aria-labelledby="example-modal-sizes-title-xl"
                            centered
                            size="lg"
                            backdrop={true}
                            onHide={() => { }}
                        >
                            <Modal.Header closeButton={true}>
                                <Modal.Title>
                                    {formatMessage({ defaultMessage: 'Thông tin liên kết kho' })}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="overlay overlay-block cursor-default">
                                {loadingScWarehouseMapping && (
                                    <div className='d-flex align-items-center justify-content-center' style={{ minHeight: 150 }}>
                                        <span className="spinner spinner-primary" />
                                    </div>
                                )}
                                {!loadingScWarehouseMapping && (
                                    <Fragment>
                                        <div className="d-flex pb-4">
                                            {OPTIONS_LINK_WAREHOUSE?.map(item => (
                                                <label key={`item--${item?.value}`} className="radio mr-4">
                                                    <input
                                                        type="radio"
                                                        checked={values?.enable_map_multiple_wh == item.value}
                                                        onChange={async (e) => {
                                                            // chuyển từ 1-1 sang 1-n hay ngược lại thì đều reset lại values
                                                            const sme_warehouse_mappings = Object.keys(values).filter(_ => _.includes('sme_warehouse_mapping'));
                                                            sme_warehouse_mappings.forEach(k => {
                                                                setFieldValue(k, null)
                                                            })
                                                            setFieldValue('enable_map_multiple_wh', item.value)
                                                        }}
                                                    />
                                                    <span></span>
                                                    &ensp;{item?.label}
                                                </label>
                                            ))}
                                        </div>

                                        {values?.isMutil && (
                                            <div className='d-flex justify-content-between align-items-center mb-2'>
                                                <div className='d-flex align-items-center'>
                                                    <img
                                                        className="mr-2"
                                                        src={toAbsoluteUrl(`/media/logo_${dataScWarehouseMapping?.scGetWarehouseMapping?.[0]?.scWarehouse?.storeChannel?.connector_channel_code}.png`)}
                                                        style={{ width: 14, height: 14, objectFit: "contain" }}
                                                    />
                                                    <span>
                                                        {dataScWarehouseMapping?.scGetWarehouseMapping?.[0]?.scWarehouse?.storeChannel?.name}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        <Table
                                            className="upbase-table"
                                            columns={[
                                                {
                                                    title: formatMessage({ defaultMessage: 'STT' }),
                                                    dataIndex: 'idex',
                                                    key: 'idex',
                                                    align: 'center',
                                                    width: '10%',
                                                    render: (item, record, index) => {
                                                        return (
                                                            <div className='d-flex flex-column'>
                                                                <span>{index + 1}</span>
                                                            </div>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: values?.isMutil ? formatMessage({ defaultMessage: 'Kho kênh bán' }) : formatMessage({ defaultMessage: 'Gian hàng' }),
                                                    dataIndex: 'id',
                                                    key: 'id',
                                                    align: 'left',
                                                    width: '50%',
                                                    render: (item, record) => {
                                                        return (
                                                            <div className='d-flex flex-column'>
                                                                {values?.isMutil ? (
                                                                    <div className='d-flex align-items-center mb-2'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2 bi bi-house-door" viewBox="0 0 16 16">
                                                                            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                                                                        </svg>
                                                                        <span>{record?.scWarehouse?.warehouse_name}</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className='d-flex align-items-center mb-2'>
                                                                        <img
                                                                            className="mr-2"
                                                                            src={toAbsoluteUrl(`/media/logo_${record?.scWarehouse?.storeChannel?.connector_channel_code}.png`)}
                                                                            style={{ width: 14, height: 14, objectFit: "contain" }}
                                                                        />
                                                                        <span>
                                                                            {record?.scWarehouse?.storeChannel?.name}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                                <span className='text-secondary-custom'>{values?.isMutil ? formatMessage({ defaultMessage: 'Địa chỉ kho hàng' }) : formatMessage({ defaultMessage: 'Địa chỉ giao hàng' })}:</span>
                                                                <span>{record?.scWarehouse?.address}</span>
                                                            </div>
                                                        )
                                                    }
                                                },
                                                {
                                                    title: formatMessage({ defaultMessage: ' Kho vật lý' }),
                                                    dataIndex: 'sme_warehouse_id',
                                                    key: 'sme_warehouse_id',
                                                    align: 'left',
                                                    width: '40%',
                                                    render: (item, record) => {
                                                        return (
                                                            <Fragment>
                                                                {!values[`isEdit-${record?.sc_warehouse_id}`] && !values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`] &&
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <span className='text-danger'>
                                                                            {formatMessage({ defaultMessage: 'Trong thời gian không liên kết, đơn hàng phát sinh không thể khóa/khấu trừ tồn kho và đồng bộ hàng tồn kho không thể thực hiện được.' })}
                                                                        </span>
                                                                        <i
                                                                            className="far fa-edit cursor-pointer ml-4"
                                                                            style={{ color: '#000000' }}
                                                                            onClick={() => setFieldValue(`isEdit-${record?.sc_warehouse_id}`, true)}
                                                                        />
                                                                    </div>}

                                                                {values?.enable_map_multiple_wh == LINK_MULTI ?
                                                                    ((!values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`] && !!values[`isEdit-${record?.sc_warehouse_id}`]
                                                                        || !!values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`])
                                                                        && <>
                                                                            <button className="btn btn-primary"
                                                                                onClick={() => {
                                                                                    setShowSelectWH(record?.sc_warehouse_id)
                                                                                    setWhSelected(prev => ({ ...prev, [record?.sc_warehouse_id]: values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`]?.map(_ => _?.value) }))
                                                                                }}
                                                                            >
                                                                                {formatMessage({ defaultMessage: "Chọn kho" })}
                                                                            </button>
                                                                            <div
                                                                                className='text-info'
                                                                                style={{ cursor: "pointer", textDecoration: "underline" }}
                                                                                onClick={() => {
                                                                                    setShowSelectWH(record?.sc_warehouse_id);
                                                                                    setWhSelected(prev => ({ ...prev, [record?.sc_warehouse_id]: values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`]?.map(_ => _?.value) }))
                                                                                }}>
                                                                                {formatMessage({ defaultMessage: "Đã chọn" })} {values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`]?.length ?? 0} {formatMessage({ defaultMessage: "kho hàng" })}
                                                                            </div>
                                                                        </>
                                                                    ) :
                                                                    <>
                                                                        {!values[`isEdit-${record?.sc_warehouse_id}`] && !!values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`] && (
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <div className='d-flex flex-column'>
                                                                                    <div className='d-flex align-items-center mb-2'>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mr-2 bi bi-house-door" viewBox="0 0 16 16">
                                                                                            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
                                                                                        </svg>
                                                                                        <span>{values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`][0]?.label}</span>
                                                                                    </div>
                                                                                    <span className='text-secondary-custom'>{formatMessage({ defaultMessage: 'Địa chỉ kho hàng' })}:</span>
                                                                                    <span>{values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`][0]?.address}</span>
                                                                                </div>
                                                                                <i
                                                                                    className="far fa-edit cursor-pointer ml-4"
                                                                                    style={{ color: '#000000' }}
                                                                                    onClick={() => setFieldValue(`isEdit-${record?.sc_warehouse_id}`, true)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {!!values[`isEdit-${record?.sc_warehouse_id}`] && (
                                                                            <div className='d-flex flex-column'>
                                                                                <div className='mb-2'>
                                                                                    <Field
                                                                                        name={`sme_warehouse_mapping_${record?.sc_warehouse_id}`}
                                                                                        component={ReSelect}
                                                                                        hideBottom
                                                                                        placeholder={formatMessage({ defaultMessage: 'Chọn kho vật lý' })}
                                                                                        label={""}
                                                                                        customFeedbackLabel={' '}
                                                                                        options={optionsSmeWarehouse}
                                                                                        isClearable={false}
                                                                                    />
                                                                                </div>
                                                                                {!!values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`] && (
                                                                                    <div className='d-flex flex-column'>
                                                                                        <span className='text-secondary-custom'>{formatMessage({ defaultMessage: 'Địa chỉ kho hàng' })}:</span>
                                                                                        <span>{Array.isArray(values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`])
                                                                                            ? values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`]?.map((_, idx) => {
                                                                                                return <div key={idx}>{_?.address}</div>
                                                                                            })
                                                                                            : values[`sme_warehouse_mapping_${record?.sc_warehouse_id}`]?.address}</span>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                }

                                                            </Fragment>
                                                        )
                                                    }
                                                }
                                            ]}
                                            data={getRow(dataScWarehouseMapping?.scGetWarehouseMapping)}
                                            emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                                                <img src={toAbsoluteUrl("/media/empty.png")} alt="image" width={80} />
                                                <span className='mt-4'>{formatMessage({ defaultMessage: 'Chưa có dữ liệu' })}</span>
                                            </div>}
                                            tableLayout="auto"
                                            sticky={{ offsetHeader: 0 }}
                                        />
                                        {isEdit && <div className='d-flex align-items-center mt-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-exclamation-triangle-fill mr-2 text-danger" viewBox="0 0 16 16">
                                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                            </svg>
                                            <span className='text-danger'>
                                                {formatMessage({ defaultMessage: 'Lưu ý: Tồn kho và đơn hàng sẽ được xử lý và đồng bộ từ kho liên kết mới' })}
                                            </span>
                                        </div>}
                                    </Fragment>
                                )}
                            </Modal.Body>
                            <Modal.Footer
                                className="form p-4 d-flex justify-content-end"
                                style={{ borderTop: '1px solid #dbdbdb' }}
                            >
                                <div className="form-group">
                                    <button
                                        type="button"
                                        onClick={onHide}
                                        className="btn btn-secondary"
                                        style={{ width: 120 }}
                                    >
                                        {isEdit
                                            ? formatMessage({ defaultMessage: 'Hủy' })
                                            : formatMessage({ defaultMessage: 'Đóng' })}
                                    </button>
                                    {isEdit && (
                                        <button
                                            type="submit"
                                            className="btn btn-primary ml-2"
                                            style={{ width: 120 }}
                                            onClick={async () => {
                                                try {

                                                    const bodyRequest = {
                                                        enable_map_multiple_wh: values?.enable_map_multiple_wh,
                                                        store_id: dataScWarehouseMapping?.scGetWarehouseMapping?.[0]?.scWarehouse?.storeChannel?.id,
                                                        mappings: Object.keys(values).map(k => { // mặc định phải đọc từ values ra, và mặc định phải map vào values khi get dữ liệu về, reset values khi chuyển trạng thái liên kết 1-1 <-> 1-n
                                                            if (k.includes('sme_warehouse_mapping')) {
                                                                // val = values[k];
                                                                // console.log("---------------", k, values, values[k]);
                                                                let data = [];
                                                                const sc_warehouse_id = +k.replace('sme_warehouse_mapping_', "");
                                                                // console.log("ccccc", values[k]);
                                                                const _val = Array.isArray(values[k]) ? values[k] : !!values[k] ? [values[k]] : []
                                                                const d = dataScWarehouseMapping?.scGetWarehouseMapping?.find(_ => _?.sc_warehouse_id == sc_warehouse_id)
                                                                if (_val.length == 0) { // trường hợp kho kênh bán không còn liên kết noà với kho vật lý
                                                                    data.push({
                                                                        maping_id: d?.id,
                                                                        sme_warehouse_id: null,
                                                                        fulfillment_provider_type: null,
                                                                        fulfillment_provider_connected_id: null,
                                                                        provider_warehouse_code: null,
                                                                        sc_warehouse_id: sc_warehouse_id
                                                                    })
                                                                }
                                                                else {
                                                                    _val.forEach(v => {
                                                                        data.push({
                                                                            maping_id: d?.id, // lấy ra id đầu tiên : trường hợp 1-n thì BE báo không cần dùng đến trường này nhưng 1-1 thì có, và BE sẽ nhận dữ liệu truyền lên từ FE để update vào db (không truyền lên = xoá) => lấy phần tử đầu tiên dùng id của nó, các phần tử khác không truyển lên = xoá bỏ
                                                                            sme_warehouse_id: v?.value || null,
                                                                            fulfillment_provider_type: v?.fulfillment_by,
                                                                            fulfillment_provider_connected_id: v?.fulfillment_provider_connected_id,
                                                                            provider_warehouse_code: v?.fulfillment_provider_wms_code || null,
                                                                            sc_warehouse_id: sc_warehouse_id
                                                                        })
                                                                    })
                                                                }
                                                                return data
                                                            }
                                                            return null
                                                        }).filter(_ => _).flat()
                                                    };

                                                    // if (!bodyRequest.enable_map_multiple_wh) {
                                                    const bodyExistSWI = bodyRequest?.mappings?.filter(item => !!item?.sme_warehouse_id);
                                                    const uniqBodyRequest = uniqBy(bodyExistSWI, 'sme_warehouse_id');

                                                    if (uniqBodyRequest?.length != bodyExistSWI?.length) {
                                                        addToast(formatMessage({ defaultMessage: 'Không được thiết lập nhiều kho kênh bán liên kết cùng một kho vật lý' }), { appearance: "error" });
                                                        return;
                                                    }
                                                    // }

                                                    // console.log("~~~~~", bodyRequest);
                                                    // return;
                                                    const { data } = await scUpdateWarehouseMapping({
                                                        variables: bodyRequest
                                                    });

                                                    if (!!data?.scUpdateWarehouseMapping?.success) {
                                                        addToast(formatMessage({ defaultMessage: 'Cập nhật thông tin liên kết kho thành công' }), { appearance: "success" });
                                                    } else {
                                                        addToast(formatMessage({ defaultMessage: 'Cập nhật thông tin liên kết kho thất bại' }), { appearance: "error" });
                                                    }
                                                    onHide();
                                                } catch (e) {
                                                    console.log("err", e);
                                                    addToast(formatMessage({ defaultMessage: 'Đã có lỗi xảy ra, xin vui lòng thử lại' }), { appearance: "error" });
                                                }
                                            }}
                                        >
                                            {formatMessage({ defaultMessage: 'Cập nhật' })}
                                        </button>
                                    )}
                                </div>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={showSelectWh}
                            aria-labelledby="example-modal-sizes-title-xl"
                            centered
                            size="lg"
                            backdrop={true}
                            onHide={() => { onHideSelectWh() }}
                        >
                            <Modal.Header closeButton={true}>
                                <Modal.Title className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                                    {formatMessage({ defaultMessage: 'Chọn kho vật lý' })}
                                    <div className='d-flex'
                                        onClick={() => {
                                            onHideSelectWh()
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                        </svg>
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="overlay overlay-block cursor-default">
                                <Fragment>
                                    <div className="col-4 input-icon p-0" style={{ height: 'fit-content' }} >
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={formatMessage({ defaultMessage: "Tên/Mã kho" })}
                                            onBlur={(e) => {
                                                setSearchText(e.target.value)
                                            }}
                                            defaultValue={''}
                                            onKeyDown={e => {
                                                if (e.keyCode == 13) {
                                                    setSearchText(e.target.value)
                                                }
                                            }}
                                        />
                                        <span><i className="flaticon2-search-1 icon-md ml-6"></i></span>
                                    </div>
                                    <div className='py-4'>{formatMessage({ defaultMessage: "Đã chọn:" })} {whSelected[showSelectWh]?.length ?? 0}/<span style={{ color: "#ff5629" }}>{dataSmeWarehouse?.sme_warehouses?.length ?? "--"}</span></div>
                                    <Table
                                        className="upbase-table"
                                        style={{ minHeight: 420 }}
                                        columns={[
                                            {
                                                title: <div className='d-flex align-items-center'>
                                                    <Checkbox
                                                        inputProps={{
                                                            'aria-label': 'checkbox',
                                                        }}
                                                        size='checkbox-md'
                                                        isSelected={whSelected[showSelectWh]?.length == dataSmeWarehouse?.sme_warehouses?.length}
                                                        onChange={(e) => {
                                                            if (whSelected[showSelectWh]?.length == dataSmeWarehouse?.sme_warehouses?.length)
                                                                setWhSelected(_ => (
                                                                    {
                                                                        ..._,
                                                                        [showSelectWh]: []
                                                                    })
                                                                )
                                                            else
                                                                setWhSelected(_ => (
                                                                    {
                                                                        ..._,
                                                                        [showSelectWh]: dataSmeWarehouse?.sme_warehouses?.map(_ => _?.id)
                                                                    }
                                                                ))
                                                        }}
                                                    />
                                                    {formatMessage({ defaultMessage: 'Tên kho' })}
                                                </div>,
                                                dataIndex: 'idex',
                                                key: 'idex',
                                                align: 'left',
                                                width: '40%',
                                                render: (item, record) => {
                                                    return (
                                                        <div className='d-flex align-items-center'>
                                                            <Checkbox
                                                                inputProps={{
                                                                    'aria-label': 'checkbox',
                                                                }}
                                                                size='checkbox-md'
                                                                isSelected={whSelected[showSelectWh]?.includes(record?.id)}
                                                                onChange={(e) => {
                                                                    const whs = whSelected[showSelectWh] ?? [];
                                                                    if (whs?.includes(record?.id))
                                                                        setWhSelected(_ => ({
                                                                            ..._,
                                                                            [showSelectWh]: whs.filter(__ => __ != record?.id)
                                                                        }))
                                                                    else {
                                                                        setWhSelected(_ => (
                                                                            {
                                                                                ..._,
                                                                                [showSelectWh]: [...whs, record?.id]
                                                                            }
                                                                        ))
                                                                    }
                                                                }}
                                                            />
                                                            <div>
                                                                <div>{record?.name}</div>
                                                                <div style={{ color: "#aaa" }}>{formatMessage({ defaultMessage: 'Mã kho: ' })}{record?.code}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            },
                                            {
                                                title: formatMessage({ defaultMessage: 'Địa chỉ kho' }),
                                                dataIndex: 'id',
                                                key: 'id',
                                                align: 'left',
                                                width: '60%',
                                                render: (item, record) => {
                                                    return (
                                                        <div className='d-flex flex-column'>
                                                            {record?.address}
                                                        </div>
                                                    )
                                                }
                                            },
                                        ]}
                                        data={dataWh?.slice(5 * (page - 1), 5 + 5 * (page - 1))}
                                        emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                                            <img src={toAbsoluteUrl("/media/empty.png")} alt="image" width={80} />
                                            <span className='mt-4'>{formatMessage({ defaultMessage: 'Chưa có dữ liệu' })}</span>
                                        </div>}
                                        tableLayout="auto"
                                        sticky={{ offsetHeader: 0 }}
                                    />
                                    <PaginationModal
                                        page={page}
                                        totalPage={totalPage}
                                        limit={5}
                                        totalRecord={totalRecord}
                                        count={dataWh?.slice(5 * (page - 1), 5 + 5 * (page - 1))?.length}
                                        onPanigate={(page) => setPage(page)}
                                        emptyTitle={formatMessage({ defaultMessage: 'Chưa có kho' })}
                                    />
                                </Fragment>
                            </Modal.Body>
                            <Modal.Footer
                                className="form p-4 d-flex justify-content-end"
                                style={{ borderTop: '1px solid #dbdbdb' }}
                            >
                                <div className="form-group">
                                    <button
                                        type="button"
                                        onClick={() => { onHideSelectWh() }}
                                        className="btn btn-secondary"
                                        style={{ width: 120 }}
                                    >
                                        {formatMessage({ defaultMessage: 'Hủy' })}
                                    </button>

                                    <button
                                        type="submit"
                                        className="btn btn-primary ml-2"
                                        style={{ width: 120 }}
                                        onClick={() => {
                                            const d = optionsSmeWarehouse?.filter(ow => whSelected[showSelectWh]?.includes(ow?.value))
                                            setFieldValue(`sme_warehouse_mapping_${showSelectWh}`, d?.length == 0 ? null : d)
                                            if (values[`sme_warehouse_mapping_${showSelectWh}`]?.length != d?.length) {
                                                setFieldValue(`isEdit-${showSelectWh}`, true); // nếu khác nhau về số lượng => có thay đổi
                                            }
                                            else {
                                                // check xem có phần tử nào khác nhau không
                                                let isDif = false;
                                                d?.forEach(item => {
                                                    if (!values[`sme_warehouse_mapping_${showSelectWh}`]?.some(_ => _?.value == item?.value)) {
                                                        isDif = true;
                                                    }
                                                })
                                                if (isDif) {
                                                    setFieldValue(`isEdit-${showSelectWh}`, true); // nếu khác nhau về giá trị => có thay đổi
                                                }
                                            }
                                            onHideSelectWh()
                                        }}
                                    >
                                        {formatMessage({ defaultMessage: 'Tiếp tục' })}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </Fragment>
                )
            }}
        </Formik>
    )
}

export default memo(MappingWarehouseDialog);